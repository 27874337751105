import { Controller } from "@hotwired/stimulus";
import { loadStripe } from '@stripe/stripe-js';

export default class extends Controller {
  async connect() {
    var stripe_public_key = document.querySelector("meta[name='stripe_public_key']").getAttribute("content");
    var stripe = await loadStripe(stripe_public_key);
    var elements = stripe.elements();
    var style = {
      base: {
        fontSize: '18px',
        color: '#32325d',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        '::placeholder': {
          color: '#aab7c4'
        }
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a'
      }
    };
    var card = elements.create('card', { style: style });
    card.mount('#stripe-dropin');

    //overriding form submit
    var form = document.getElementById('stripe-form');
    form.addEventListener('submit', function (event) {
      event.preventDefault();
      stripe.createToken(card).then(function (result) {
        if (result.error) {
          var errorElement = document.getElementById('stripe-dropin-errors');
          errorElement.textContent = result.error.message;
        } else {
          var hiddenInput = document.createElement('input');
          hiddenInput.setAttribute('type', 'hidden');
          hiddenInput.setAttribute('name', 'stripe_token');
          hiddenInput.setAttribute('value', result.token.id);
          form.appendChild(hiddenInput);
          form.submit();
        }
      });
    });
  }
}
