import { Controller } from "@hotwired/stimulus";
import { default as $ } from 'jquery';

const AdminPaymentForm = {
  stripeForm: $('form.stripe-form'),
  cashOrCheckForm: $('form.cash-or-check-form'),
  paymentMethod: $('input#payment_payment_method'),
  initialize: function () {
    AdminPaymentForm.cashOrCheckForm.hide();
    AdminPaymentForm.stripeForm.hide();
    AdminPaymentForm.initializeFormToggle();
    AdminPaymentForm.setFormMode('credit-card');
  },
  initializeFormToggle: function () {
    $('input[type=radio][name=payment-method-toggle]').on('change', function () {
      AdminPaymentForm.setFormMode(this.value);
    });
  },
  setFormMode: function (fType) {
    if (fType == 'credit-card') {
      AdminPaymentForm.cashOrCheckForm.hide();
      AdminPaymentForm.stripeForm.show();
      AdminPaymentForm.paymentMethod.val(fType);
    } else if (fType == 'cash' || fType == 'check') {
      AdminPaymentForm.stripeForm.hide();
      AdminPaymentForm.cashOrCheckForm.show();
      AdminPaymentForm.paymentMethod.val(fType);
    }
  }
};

export default class extends Controller {
  connect() {
    AdminPaymentForm.initialize();
  }
}
